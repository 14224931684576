<template>
  <b-modal id="extra-health-verification"
           hide-header-close
           @shown="renderModal"
           size="lg" >
    <template #modal-header>
      <div class="popUp-header" style="border-bottom: 0px !important">
        <b-button variant="close-btn" @click="$bvModal.hide('extra-health-verification')">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <div class="verification-container"  ref="extraHealthVerification">
      <h2 class="text-uppercase">Client Signature</h2>
      <div class="mt-3">
        <label class="text-uppercase">Send text verification</label>
        <div class="form-group row">
          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12  mb-4">
            <input type="number" class="custom-input"
                   id="phoneNumber"
                   placeholder="Enter phone number"
                   @input="$v.sendPhoneNumber.$touch(); removeError()"
                   :class="{'form-control is-invalid':$v.sendPhoneNumber.$error || errorPhoneMsg}"
                   v-model="sendPhoneNumber"
                   value=""
                   maxlength="10" ref="phone" >
            <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.sendPhoneNumber.required && $v.sendPhoneNumber.$error">
              Field is required
            </div>
            <div class="form-group__message text-left ml-3 mt-2" v-if="$v.sendPhoneNumber.required && $v.sendPhoneNumber.$invalid">
              Invalid phone number
            </div>
            <div class="error_message" v-if="errorPhoneMsg">{{errorPhoneMsg}}</div>
          </div>
          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 mb-3">
            <b-button  class="text-uppercase send-verification-button" @click="checkPhoneNumberVerification">Send</b-button>
            <!-- <span class="status">Status <strong class="queued-state"><span class="tick-wrap"><img src="../../../assets/images/queued.svg"/></span>Queued</strong></span> -->
            <!-- <span class="status">Status <strong class="sent-state"><span class="tick-wrap"><img src="../../../assets/images/sent.svg"/></span>Send</strong></span> -->
            <!-- <span class="status">Status <strong class="delivered-state"><span class="tick-wrap"><img src="../../../assets/images/delivered.svg"/></span>Delivered</strong></span> -->
            <!--              <span class="status"><strong class="queued-state"><span class="tick-wrap"><img src="../../../assets/images/queued.svg"/></span>Queued</strong></span>-->
            <span v-if="showPhoneValidTick"> <i class="fa fa-check-circle fa-2x email-phone-alert-success mr-2"></i></span>
          </div>
        </div>
      </div>
      <div>
        <label class="text-uppercase">Send Email verification</label>
        <div class="form-group row">
          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12  mb-4">
            <input type="text" class="custom-input"
                   id="email"
                   placeholder="Enter email address"
                   @input="$v.sendEmail.$touch(); removeError()"
                   :class="{'form-control is-invalid':$v.sendEmail.$error || errorEmailMsg}"
                   v-model="sendEmail"
                   value="" ref="email">
            <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.sendEmail.required && $v.sendEmail.$error">
              Field is required
            </div>
            <div class="form-group__message text-left ml-3 mt-2" v-if="$v.sendEmail.required && $v.sendEmail.$invalid">
              Enter Valid Email
            </div>
            <div class="error_message" v-if="errorEmailMsg">{{errorEmailMsg}}</div>
          </div>
          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 mb-3">
            <b-button  class="text-uppercase send-verification-button" @click="checkEmailVerification">Send</b-button>
            <!-- <span class="status">Status <strong class="queued-state"><span class="tick-wrap"><img src="../../../assets/images/queued.svg"/></span>Queued</strong></span> -->
            <!-- <span class="status">Status <strong class="sent-state"><span class="tick-wrap"><img src="../../../assets/images/sent.svg"/></span>Send</strong></span> -->
            <!-- <span class="status">Status <strong class="delivered-state"><span class="tick-wrap"><img src="../../../assets/images/delivered.svg"/></span>Delivered</strong></span> -->
            <!--              <span class="status"><strong class="open-state"><span class="tick-wrap"><img src="../../../assets/images/opened.svg"/></span>Opened</strong></span>-->
            <span v-if="showEmailValidTick"><i class="fa fa-check-circle fa-2x email-phone-alert-success mr-2"></i></span>
          </div>
        </div>
      </div>
      <div v-if="renderOTPInfo">
        <div class="mb-4 verificaitonBoxWrap" v-if="!(hasSignatureSubmitted && paymentStatus)">
            <p>Or have your client goto <a :href="c123LinkUrl" target="_blank"><strong class="c123-link"> c123.io  </strong></a><br />
            Give them this code <strong>{{thirdPartyVerifyOTP}}</strong> to enter and verify and sign for enrollment.</p>
            <div>
            <otp-code-expiry    @sendOTP="getOtpExpiry()"
                                @destroyExpiryInfo="destroyExpiryInfo()"
                                :expirySecond=otpDifferTime />
          </div>
        </div>
      </div>
      <div class="form-group  mt-2 mb-2">
        <div class="mb-2 verify-action-btn">
          <b-button  class="text-uppercase check-verification-button w-100" @click="checkSignatureValidation(true)" v-if="!hasSignatureSubmitted">
            <span class="verify-text">Check Signature Verification</span><img src="../../../assets/images/signature.svg" alt="">
          </b-button>
          <!-- verified Btn -->
          <b-button class="verified-btn text-uppercase w-100" v-if="hasSignatureSubmitted">Verified Signature<img src="../../../assets/images/verified-tick.svg"/></b-button>
          <b-button class="payment-verification-button text-uppercase w-100" v-if="paymentStatus" disabled> Verified Payment<img src="../../../assets/images/verified-tick.svg"/></b-button>
          <b-button class="payment-verification-button text-uppercase me-0" v-if="!paymentStatus"
                    @click="checkSignatureValidation(true)"
                    w-100
                    :disabled="!hasSignatureSubmitted">
            Check Payment Verification<img src="../../../assets/images/money-card.svg"/></b-button>
          <!--               disable payment-->
          <!--               <b-button class=" payment-diasble payment-verification-button text-uppercase me-0">
                          Check Payment Verification<img src="../../../assets/images/money-card.svg"/></b-button>-->
        </div>
      </div>
      <div v-if="showVerificationMsg && showCheckVerificationMsg" class="mb-2">
        <div class="verification-alert-success" v-if="hasSignatureSubmitted && paymentStatus">
          <span>Signature has been received and the payment is successful, please proceed to complete your enrollment.</span>
        </div>
        <div class="verification-alert-danger" v-else-if="!hasSignatureSubmitted">
          Signature has not been added. Please follow the link sent to the email or phone, or have your client goto <a :href="c123LinkUrl" target="_blank"><strong class="c123-link">c123.io</strong></a>
          Give them this code <strong>{{thirdPartyVerifyOTP}}</strong> to enter and verify and sign for enrollment.
        </div>
      </div>
      <div v-if="showPaymentError" class="mb-2">
        <div class="verification-alert-danger">
          {{paymentErrorMsg}}
          <br>
          <u style="
                color: #0094d9;
                font-weight: 600;
                cursor: pointer;" @click="routeToCC">Click here to change payment detail</u>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12  mb-3">
          <b-button  class="text-uppercase send-verification-button w-100" :disabled="!(hasSignatureSubmitted && paymentStatus)" @click="submitData">Complete Enrollment</b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";
import {checkValidPhoneNumber} from "../../../utils/validators";
import axios from "axios";
import html2canvas from "html2canvas";
import OtpCodeExpiry from "./OtpCodeExpiry";
export default {
  name: "ExtraHealthVerificationModal",
  data () {
    return {
      sendEmail: '',
      sendPhoneNumber: '',
      tempId: '',
      errorPhoneMsg: '',
      errorEmailMsg: '',
      showEmailValidTick: false,
      showPhoneValidTick: false,
      showVerificationMsg: false,
      hasSignatureSubmitted: false,
      renderSpinnerIcon: false,
      showPaymentError: false,
      paymentErrorMsg: '',
      paymentStatus: false,
      showCheckVerificationMsg: true,
      thirdPartyVerifyOTP: '',
      c123LinkUrl: process.env.VUE_APP_C123_URL,
      isCopied: false,
      otpExpiry: '',
      otpDifferTime: '',
      renderOTPInfo: false
    }
  },
  props: {
    contactInfo: {
      type: Object
    }
  },
  validations: {
    sendEmail: {
      required,
      email
    },
    sendPhoneNumber: {
      required,
      checkValidPhoneNumber
    }
  },
  components: {
    'otp-code-expiry': OtpCodeExpiry
  },
  mounted() {
    let app = this
    app.tempId = window.localStorage.getItem('tempId');
  },
  methods: {
    checkEmailVerification () {
      let app = this
      app.showVerificationMsg = false
      app.removeError();
      app.$v.sendEmail.$touch();
      if (app.$v.sendEmail.$invalid) {
        return
      } else {
        app.sendVerificationLink (app.sendEmail, 'email');
      }
    },
    checkPhoneNumberVerification () {
      let app = this
      app.showVerificationMsg = false
      app.removeError();
      app.$v.sendPhoneNumber.$touch();
      if (app.$v.sendPhoneNumber.$invalid) {
        return
      } else {
        app.sendVerificationLink (app.sendPhoneNumber, 'phone');
      }
      app.captureScreenShotCanvas('check-phone-number-verification')
    },
    checkSignatureValidation (status) {
      let app = this
      app.renderSpinnerIcon = true
      app.showCheckVerificationMsg = status
      let paramsObj = {
        'enrollment_id': app.tempId
      }
      axios.get(process.env.VUE_APP_API_BASE+'/enrollment-link/check-signature', {
        params:  paramsObj
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              let data = response.data.data
              app.commonCheckSignatureVerification(data)
            }
          })
          .catch(function (error) {
            if (error.response.data.status == 'error') {
              console.log('error', error.response)
              app.renderSpinnerIcon = false
              setTimeout(() => {
                app.captureScreenShotCanvas('signature-validation-failed')
              }, 1000)
            }
          });
    },
    checkForPaymentStatus (paymentStatusInfo) {
      let app = this
      if (!paymentStatusInfo.status) {
        /* if payment is failed */
        app.showPaymentError = true
        app.paymentErrorMsg =  paymentStatusInfo.message
        app.$emit('paymentInfo', paymentStatusInfo)
      }
      if (paymentStatusInfo.status) {
        /* if payment is success */
        app.showPaymentError = false
        app.paymentStatus = paymentStatusInfo.status
        app.submitData();
      }
      (app.paymentStatus && app.hasSignatureSubmitted) && (app.showCheckVerificationMsg = true)
      setTimeout(() => {
        app.captureScreenShotCanvas('check-payment-status')
      }, 1000)
    },
    sendVerificationLink (data, type) {
      let app = this
      let paramsObj = {
        'enrollment_id': app.tempId,
        'data': data,
        'type': type
      }
      axios.get(process.env.VUE_APP_API_BASE+'/enrollment-link/send', {
        params:  paramsObj
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              (type === 'email') && (app.showEmailValidTick = true);
              (type === 'phone') && (app.showPhoneValidTick = true);
              app.$notify({
                title: response.data.message,
                icon: 'ti-alert',
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'success'
              })
            }
            setTimeout(() => {
              app.captureScreenShotCanvas(`${type}-verification-success`)
            }, 1000)
          })
          .catch(function (error) {
            if (error.response.data.status == 'error') {
              (type === 'email') && (app.errorEmailMsg = error.response.data.message);
              (type === 'phone') && (app.errorPhoneMsg = error.response.data.message);
              setTimeout(() => {
                app.captureScreenShotCanvas(`${type}-verification-error`)
              }, 1000)
            }
          });
    },
    removeError () {
      let app = this
      app.errorPhoneMsg = ''
      app.errorEmailMsg = ''
    },
    renderModal () {
      let app = this
      app.setupWebSocket();
      app.errorPhoneMsg = '';
      app.errorEmailMsg = '';
      app.sendEmail = app.contactInfo.email_address ? app.contactInfo.email_address : '';
      app.sendPhoneNumber = app.contactInfo.phone_number ? app.contactInfo.phone_number : '';
      app.showEmailValidTick = false;
      app.showPhoneValidTick = false;
      app.showVerificationMsg = false,
          app.showPaymentError = false
      app.paymentStatus = false
      app.showCheckVerificationMsg = false
      app.$v.$reset();
      app.checkSignatureValidation(false);
      setTimeout(() => {
        app.captureScreenShotCanvas('render-verification-modal')
      }, 1000)
    },
    submitData () {
      let app = this
      app.$emit('submitEnrollment')
    },
    async captureScreenShotCanvas (captureScreenShotName) {
      const el = this.$refs.extraHealthVerification;
      (async () => {
        this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), captureScreenShotName);
      })()
    },
    saveScreenShot (image, name) {
      let app = this;
      let data = {
        "enrollment_id" : app.tempId,
        "name" : name,
        "image" : image
      };
      let config = {
        method: 'post',
        url: process.env.VUE_APP_API_BASE+'/save-screenshot',
        headers: { },
        data : data
      };
      axios(config)
          .then(function (response) {
            if (response.data.status == 'success') {
              console.log('screenshot saved');
            }
          })
          .catch(function (error) {
            console.log('screenshot not saved');
            console.log(error);
          });
    },
    routeToCC () {
      let app = this
      app.$emit('extraHealthModalRouteToCC')
    },
    commonCheckSignatureVerification (data) {
      let app = this
      let responseData = data
      app.showVerificationMsg = true
      app.hasSignatureSubmitted = responseData.has_already_submitted
      app.renderSpinnerIcon = false
      app.thirdPartyVerifyOTP = responseData.otp
      app.otpExpiry = responseData.otp_expiry
      app.checkOtpExpiryValidation(app.otpExpiry);
      app.hasSignatureSubmitted && app.checkForPaymentStatus(responseData.payment_response)
      setTimeout(() => {
        app.captureScreenShotCanvas('signature-validation-success')
      }, 1000)
      app.renderOTPInfo = true
    },
    setupWebSocket() {
      let app = this
      app.showCheckVerificationMsg = true
      app.socket = new WebSocket(`${process.env.VUE_APP_COMMON_WEB_SOCKET_URL}?enrollment_id=${app.tempId}`);
      app.socket.onopen = () => {};
      app.socket.onmessage = (event) => {
        console.log('connection event', event)
        const data = JSON.parse(event.data);
        if (data.event_type == 'check-signature') {
          app.commonCheckSignatureVerification(data.data)
        }
      };
      app.socket.onclose = () => {};
    },
    copyToClipboard() {
      if (this.thirdPartyVerifyOTP) {
        navigator.clipboard.writeText(this.thirdPartyVerifyOTP).then(() => {
          this.isCopied = true
          setTimeout(() => {
            this.isCopied = false
          }, 3000)
        }).catch(err => {
          console.log(err);
        });
      }
    },
    getOtpExpiry () {
      let app = this;
      let data = {
        "enrollment_id" : app.tempId
      };
      let config = {
        method: 'put',
        url: process.env.VUE_APP_API_BASE+'/generate-otp',
        data : data
      };
      axios(config)
          .then(function (response) {
            if (response.data.status == 'success') {
              app.thirdPartyVerifyOTP = response.data.data.otp
              app.otpExpiry = response.data.data.otp_expiry
              app.checkOtpExpiryValidation(app.otpExpiry);
              app.renderOTPInfo = true
            }
          })
          .catch(function (error) {
            app.renderOTPInfo = false
            console.log(error);
          });
    },
    checkOtpExpiryValidation (otpExpiry) {
      let app = this
      if (!otpExpiry) return 0;
      const expiryDate = new Date(otpExpiry);
      const currentDate = new Date();
      const diffInSeconds = Math.floor((expiryDate - currentDate) / 1000);
      diffInSeconds > 0 ? (app.otpDifferTime = diffInSeconds ) : app.getOtpExpiry();
    },
    destroyExpiryInfo() {
      let app = this
      app.renderOTPInfo = false
    }
  }
}
</script>

<style lang="scss" scoped>
#extra-health-verification {
  .verification-container {
    padding-left: 10px;
  }

  h2 {
    font-size: 22px;
    font-weight: 600;
  }

  .send-verification-button {
    background: #00A550;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    outline: none;
    border: 0px;
    padding: 8px 30px;
    transition: all ease 0.4s;
    margin-right: 15px;
    margin-top: 3px;
  }
  .verify-action-btn{
    display: flex;
    @media (max-width:991px) {
      display: block;
    }
  }
  .check-verification-button, .payment-verification-button {
    background: #0094D9;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    border-radius: 6px;
    outline: none;
    border: 0px;
    padding: 8px 30px;
    transition: all ease 0.4s;
    margin-right: 15px;
    margin-top: 3px;
  }
  .check-verification-button img{
    filter: brightness(0) invert(1);
    width: 20px;
    height: 20px;
    margin-bottom: 0.1rem;
  }
  .payment-verification-button{
    background: #c1ab22;
    width: -webkit-fill-available;
    @media (max-width:991px) {
      margin-top: 0.5rem;
    }
  }
  .payment-diasble {
    background: #c6ba6c;
  }
  .payment-verification-button img{
    filter: brightness(0) invert(1);
    width: 20px;
    height: 20px;
    margin-left: 0.4rem;
    margin-bottom: 0.1rem;
  }
  .email-phone-alert-success {
    color: #2DB223;
    border-radius: 5px;
    position: relative;
    top: 10px;
  }
  .status{
    border-left: 1px solid #e4e4e4;
    padding: 0.5rem;
  }
  .sent-state ,.delivered-state ,.queued-state ,.open-state{
    border-radius: 39px;
    color: #013f79;
    border: 1px solid #013f79;
    background: #e7f3ff;
    font-weight: 500;
    font-size: 14px;
    padding: 10px 15px 10px 8px;
  }
  .open-state{
    color: #00babd;
    border: 1px solid #00babd;
    background: hwb(175 91% 0%);
  }
  .queued-state{
    color: #b7bd00;
    border: 1px solid #b7bd00;
    background: #ffffe7;
  }
  .tick-wrap{
    margin:0.1rem 0.2rem;
    img{
      position: relative;
      bottom: 0.16rem;
    }
  }
  .verified-btn {
    background-color: #59C48D;
    width: -webkit-fill-available;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
    outline: none;
    border: 0px;
    padding: 8px 30px;
    transition: all ease 0.4s;
    margin-top: 3px;
    margin-right: 15px;
    cursor: text;

    img{
      margin-left: 0.4rem;
      filter: brightness(0) invert(1);
      width: 15px;
      height: 15px;
      bottom: 0.15rem;
    }
  }

  .delivered-state{
    color: #017917;
    border: 1px solid #017917;
    background: #e7ffee;
  }
  .verification-alert-success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
    padding: 15px 8px;
    margin: 0;
    border-radius: 5px;
  }
  .verification-alert-danger {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    padding: 15px 8px;
    margin: 0;
    border-radius: 5px;
  }
  .rotational-spinner {
    animation: rotate 0.6s linear infinite;
    width: 20px;
    height: 20px;
    display: inline-block;
  }

  .verify-text {
    padding: 0 10px;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    background-image: none !important;
  }

}
.verificaitonBoxWrap{
  border: 1px solid #eaeaea;
  padding: 10px 15px;
  border-radius: 8px;
  border-left: 6px solid green;
}
.verificaitonBox{
  font-size: 1.5rem;
   font-weight: 600;
}
.verificaitonBox svg{
  font-size: 22px;
  cursor: pointer;
  color: #888;
  margin-left: 10px;
}
.verificaitonBoxWrap p{
  font-size: 14px;
  margin: 0;
}
.copy-icon span{
  font-size: 14px;
  font-weight: 400;
}
.c123-link {
  color: #0d6EFD;
  text-decoration: underline;
}
</style>