<template>
  <div v-if="remaningSecond">
    <div class="otp-timer-wrap">
      <div>
       <span class="">
          <i class="fa fa-clock me-1 clock-icon"></i>
          OTP will expire in <span class="resend-timer"> {{formattedTime()}}</span>.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Cookies from "js-cookie";
export default {
  name: "NewLoginOTP",
  props: {
    expirySecond: {
      type: Number,
      required: true,
      default: 0,
    }
  },
  data() {
    return {
      remaningSecond: 0,
      interval: null
    };
  },
  mounted() {
    this.setTimer();
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.$emit('destroyExpiryInfo')
  },
  methods: {
    setTimer() {
      this.remaningSecond = this.expirySecond;
      this.setOTPInterval();
    },
    setOTPInterval() {
      if (this.interval) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        if (this.remaningSecond > 0) {
          this.remaningSecond--;
        } else {
          clearInterval(this.interval);
          this.$emit("sendOTP");
        }
      }, 1000);
    },
    sendOtpCode() {
      if(this.remaningSecond > 0) return;
      this.$emit("sendOTP");
      this.setTimer();
    },
    formattedTime() {
      let minutes = Math.floor(this.remaningSecond / 60);
      let seconds = this.remaningSecond % 60;

      if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? 's' : ''} and ${seconds} second${seconds > 1 ? 's' : ''}`;
      } else {
        return `${seconds} second${seconds > 1 ? 's' : ''}`;
      }
    }
  },
  watch: {
    expirySecond(newValue) {
      this.setTimer();
    }
  }
};
</script>

<style scoped>
.otp-timer-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0px 2px 0px;
  font-size: 15px;
  color: #0094D9;
  font-weight: 600;
}

.otp-timer-wrap .resend-otp-code {
  text-align: right;
  cursor: pointer;
  color: #11809c;
}
.otp-timer-wrap .disable-otp {
  cursor: default;
  text-decoration: none;
}
@media (max-width:991px) {
  .otp-timer-wrap {
    display: block;
  }
}
</style>