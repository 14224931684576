<template>
  <div class="page-wrap receiptPage">
    <div class="container">
<!--      <div>
        <extra-health-alert-message />
      </div>-->
      <div class="row justify-content-center">
        <div class="col-xxl-6">
          <div class="Thanksimg mb-4">
            <img :src="thankYouImage" alt="Corenroll">
          </div>
          <h2>
            <span class="head_grey">Dear {{name}},<br>Thank you,</span>
          </h2>
          <p>Your confirmation number is #{{policyID}}. A receipt of your enrollment will be sent to you and also to the client with the subject "Enrollment Receipt"</p>
          <div class="rollback-btn mb-5">
            <button class="backHome" type="button" name="button" @click="homePage">Return to homepage</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
import {checkExtraHealthGroup} from "../utils/checkPlatform";
/*import ExtraHealthAlertMessage from "./Enrollment/partials/ExtraHealthAlertMessage";*/

export default {
    name: "Confirmation",
/*    components: {
      ExtraHealthAlertMessage
    },*/
    data: function () {
      return {
        policyID: '',
        name: '',
        tempId: '',
        eprocess: '',
        thankYouImage: '',
        effectiveDate: ''
      }
    },
  computed: {
    landingInfo() {
      return this.$store.getters.getLandingInfo
    },
  },
  mounted() {
    let app = this;
    app.tempId = window.localStorage.getItem('tempId');
    app.eprocess = window.localStorage.getItem("eprocess");
    app.open_type = window.localStorage.getItem("open_type");
    app.referral = window.localStorage.getItem("referral");
    app.fromSite = window.localStorage.getItem('fromSite');
    app.effectiveDate = window.localStorage.getItem("effectiveDate");
    app.checkEprocess();
    app.scrollTop();
    app.fetchEff();
    app.fetchCatchInfo();
    app.$bus.$emit("resetCartInfo");
  },
    methods: {
        fetchEff: function () {
            let app = this;
            axios.get(process.env.VUE_APP_API_BASE+'/get-receipt', {
            params: {
                enrollment_id: app.tempId,
            }
            })
            .then(function (response) {
                console.log(response);
                if (response.data.status == 'success') {
                  app.policyID = response.data.data.policy_id;
                  app.name = response.data.data.name;
                  // if callInvoice is true then call generate invoice api..
                  response.data.callInvoice && app.generateInvoiceForEnrollment();
                  window.localStorage.removeItem('tempId');
                  window.localStorage.removeItem('dep_sync_temp_id');
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        generateInvoiceForEnrollment() {
          let app = this;
          axios.post(
              process.env.VUE_APP_API_BASE_INVOICE + '/autoInvoiceGeneration',
              {
                effectiveDate: app.effectiveDate,
                policy_id: app.policyID
              },
              {
                headers: {
                  'Authorization': `${process.env.VUE_APP_AUTHORIZATION_INVOICE}`
                }
              }).then(function (response) {
                console.log(response.data.data.result);
                if (response.data.status === 'success') {
                  console.log('Invoice generated');
                }
              })
              .catch(function (error) {
                console.log(error);
              });
        },
        checkEprocess: function() {
          let app = this;
          axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
            params: {
              enrollment_id: app.tempId,
              column: 'eprocess',
            }
          })
          .then(function (response) {
            console.log(response.data.data.result);
            if (response.data.status == 'success') {
              app.eprocess = response.data.data.result;
            }

          })
          .catch(function (error) {
            console.log(error);
          });
        },
        homePage: function () {
      if (this.open_type !== null && typeof this.open_type !== "undefined") {
        if (this.referral !== null && typeof this.referral !== "undefined") {
          this.$router.push("/referrals?rid=" + this.referral);
        } else if (this.checkExtraHealthRedirection()) {
          this.$router.push('/plans');
        } else {
          this.$router.push('/');
        }
      } else {
        if (this.eprocess === "rep") {
          this.$router.push("/agent-home");
        } else if (this.eprocess === "applicant") {
          this.$router.push("/member-home");
        } else if (this.eprocess === "group") {
          this.$router.push("/group-home");
        }
      }
    },
    scrollTop(){
      window.scrollTo(0,0);
    },
    checkExtraHealthRedirection () {
        let app = this
        return (checkExtraHealthGroup(app.landingInfo))
    },
    fetchCatchInfo() {
      let siteDomain = window.btoa(unescape(encodeURIComponent(process.env.VUE_APP_SITE_DOMAIN)))
      // let siteDomain = 'aHR0cHM6Ly9lbnJvbGwucHVyZW5yb2xsLmNvbQ=='
      let url = `${process.env.VUE_APP_API_BASE}/get-external-site-dynamic-information?site_domain=${siteDomain}`
      caches.open('fetch_color_cache').then((cache) => {
        cache.match(url).then(response => {
          response.json().then(data => {
            let getCatchColor = []
            let newObj = {}
            getCatchColor = data
            getCatchColor.map(item => {
              newObj[item.key] = item.value;
            });
            this.thankYouImage = newObj["VUE_APP_THANK_YOU"]
          })
        })
      }).catch((err) => {
        console.log(err);
      })
    }
  }
}
</script>

<style scoped>

</style>
