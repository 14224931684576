<template>
  <div>
<!-- This landing page is for aetna enroll for certain rep which want to bypass normal landing, plan, plan detail and cart page    -->
    <div class="landingHeader py-1">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-5">
            <img src="./images/aetnadental.png" class="w-100 img-fluid" alt="">
          </div>
          <div class="col-sm-3">
            <img src="./images/teledental.png" class="img-fluid" alt="">
          </div>
          <div class="col-sm-1">
            <img src="./images/outlook.jpeg" class="img-fluid" alt="">
          </div>
          <div class="col-sm-3">
            <img src="./images/mg.png" class="img-fluid" alt="">
          </div>
          <!-- <div class="col-lg-10 col-12">
            <div class="logoList">
              <img src="./images/aetnadental.png" class="aetnaLogo" alt="">
              <ul>
                <li>
                  <img src="./images/teledental.png" alt="">
                </li>
                <li>
                  <img src="./images/outlook.jpeg" alt="">
                </li>
                <li>
                  <img src="./images/mg.png" style="max-width: 250px;" alt="">
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2">
            <div class="enrollNow">
              <button class=""  v-b-modal.aetna-modal>Click Here</button>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="landingBanner">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <img src="./images/banner.png" class="img-fluid" alt="">
          </div>
        </div>
      </div>

    </div>
    <div class="landingContentwrap pb-2">
      <div class="container container-lg-fluid">
        <div class="row">
          <div class="col-12 mobile-show">
            <div class="content-bannerLanding">
              <img src="./images/content-banner.png" class="img-fluid" alt="">
              <h5>Talk with a Live Dentist on Teledental.com Anywhere, 24/7</h5>
              <p class="mb-0">Want to consult with a dentist online?</p>
              <p class="mb-0">Use <u>Teledental video consultation</u> with a <u>Dentist</u></p>
              <p class="mb-0">Consult with a <u>live Dentist</u> 24/7 - any time, from any place </p>
            </div>
          </div>
          <div class="col-xl-7 col-lg-8">
            <div class="landingContent aetnaBlock">
              <img src="./images/aetnadental.png" class="img-fluid mb-4 mt-4" alt="">
              <h2>Savings Program Highlights:</h2>
              <ul class="mb-4">
                <li>No Annual Limits allow year long savings.</li>
                <li>No forms to file or unnecessary paperwork.</li>
                <li>No required referrals to access specialists.</li>
                <li>No Age or Health Restrictions - Everyone Accepted.</li>
                <li>No SS# required.</li>
              </ul>
              <h2>Program Overview</h2>
            </div>
            
          </div>
          <div class="col-xl-5 col-lg-4 mobile-hide">
            <div class="content-bannerLanding ">
              <img src="./images/content-banner.png" class="img-fluid" alt="">
              <h5>Talk with a Live Dentist on Teledental.com Anywhere, 24/7</h5>
              <p class="mb-0">Want to consult with a dentist online?</p>
              <p class="mb-0">Use <u>Teledental video consultation</u> with a <u>Dentist</u></p>
              <p class="mb-0">Consult with a <u>live Dentist</u> 24/7 - any time, from any place </p>
            </div>
          </div>
          <div class="col-12">
            <div class="landingContent aetnaBlock">
              <ul>
                <li>In most instances, members save up to 50% per visit on certain dental services including: braces, cleanings, x-rays, root canals, corwns, dentures, implants and more!
                Save at general dentists and specialists such as Endodontist, Orthodontist, Periodontists, Oral Surgeons and other specialists, more than 262,000** dental practice locations available nationwide
                For provides please visit <a href="https://dentallookup.com" target="_blank">www.dentallookup.com</a></li>
              </ul>
            </div>
          </div>
          <div class="col-12">
            <div class="landingContent py-3">
              <div class="text-center enrollNow">
                <button class="" v-b-modal.aetna-modal>Click here</button>
                <div class="contactInfo mt-3">
                  <a href="tel:">
                    <i class="fas fa-phone-alt"></i> XXX-XXX-XXXX
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="landingContentwrap pt-0">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 mb-4">
            <div class="landingContent">
              <img src="./images/outlook.jpeg" style="max-width: 185px;" alt="">
              <ul>
                <li>Nationwide vision care discount network consisting of over 11,500 providers.</li>
                <li>Average discounts from 10% to 50% on frames, prescription lenses and sunglasses.</li>
                <li>Mail-order contact lenses replacement program.</li>
                <li>Discounts on corrective surgery.</li>
                <li>No paperwork.</li>
                <li>Customer services representatives (rather than recodings) answer questions and direct</li>
                <li>Members to Outlook Vision provides.</li>
                <li>100% guaranteed satisfaction for 30 days.</li>
                <li>Membership includes the entire family.</li>
              </ul>
              <p>Provider finder: <a href="https://outlookvision.com" target="_blank">www.outlookvision.com</a></p>
            </div>
          </div>
          <div class="col-lg-6 mb-4">
            <div class="landingContent">
              <img src="./images/mg.png" class="w-100 img-fluid mb-3" alt="">
              <p>Select Rx Discount is a membership designed to help your save money. <br> Free and Discounted Prescription Medication</p>
              <ul>
                <li>Over 500 prescription medications</li>
                <li>Membership starts at $10 a month</li>
                <li>Membership includes free Acute Medications, Maintenance Medications, Retail Rx Sacings Card, Brand - Name and Generic Mail - Order Savings, Diabetic Supplies Savings, Vitamin Savings</li>

              </ul>
              <a href="https://www.medaffordglobal.com/index.aspx" target="_blank">https://www.medaffordglobal.com/index.aspx</a>
            </div>
          </div>
          <div class="col-12">
            <div class="text-center enrollNow mt-2">
              <button class=""  v-b-modal.aetna-modal>Click Here</button>
              <div class="contactInfo mt-3">
                <a href="tel:">
                  <i class="fas fa-phone-alt"></i> XXX-XXX-XXXX
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <aetna-enroll-now-modal />
  </div>

</template>

<script>
import './styles/style.css'
import AetnaEnrollNowModal from "./modal/AetnaEnrollNowModal";
export default {
  name: "AetnaDentalPage",
  components: {AetnaEnrollNowModal}
}
</script>