<template>
  <div>
    <b-modal id="aetna-modal" centered hide-header-close @shown="renderModal">
      <div class="modal-body pt-0">
        <div v-if="!renderProceedConformation">

          <div class="form-group row">
            <div class="col-md-6 mb-3">
              <div :class="{ 'invalid-field': $v.zip.$error }">
                <label for="">Zip Code</label>
                <input type="number" name="" v-model="zip" id="zip" value="" placeholder="Enter your Zip Code"
                       class="custom-input" @input="$v.zip.$touch(); removeError();"
                       :class="{ 'form-control is-invalid': $v.zip.$error || formErrors['zip_message'] }"
                       oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                       maxlength="5">
                <div class="form-group__message text-left ml-3 mt-2" v-if="!$v.zip.required && $v.zip.$error">
                  Zip field is required
                </div>
                <div class="form-group__message  ml-3 mt-2" v-if="$v.zip.required && $v.zip.$error">
                  Invalid Zip code
                </div>
                <div class="error_message" v-if="formErrorFlag['zip'] == true">{{ formErrors['zip_message'] }}</div>
              </div>
            </div>
<!--            <div class="col-md-6 mb-3">
              <div :class="{ 'invalid-field': $v.gender.$error }">
                <label for="">Gender</label>
                <div>
                  <b-form-select v-model="gender" class="custom-input" @input="$v.gender.$touch(); removeError();"
                                 :class="{ 'form-control is-invalid': $v.gender.$error || formErrors['gender_message'] }" id="gender"
                                 :options="genderOptions">
                  </b-form-select>
                </div>
                <div class="form-group__message text-left ml-3 mt-2" v-if="!$v.gender.required && $v.gender.$error">
                  Gender field is required
                </div>
                <div class="error_message" v-if="formErrorFlag['gender'] == true">{{ formErrors['gender_message'] }}</div>
              </div>
            </div>-->
            <div class="col-md-6 mb-3">
              <div :class="{ 'invalid-field': $v.tier.$error }">
                <label for="">Choose Tier</label>
                <b-form-select v-model="tier" class="custom-input" @input="$v.tier.$touch(); removeError();"
                               :class="{ 'form-control is-invalid': $v.tier.$error || formErrors['tier_message'] }" id="tier"
                               :options="tierOptions">
                </b-form-select>
                <div class="form-group__message text-left ml-3 mt-2 mb-2" v-if="!$v.tier.required && $v.tier.$error">
                  Tier field is required
                </div>
                <div class="error_message mb-2" v-if="formErrorFlag['tier'] == true">{{ formErrors['tier_message'] }}
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div :class="{ 'invalid-field': $v.dob.$error }">
                <label for="">DOB</label>
                <v-date-picker v-model="dob" :value="null" :popover="{ visibility: 'click' }" :max-date="new Date()"
                               @input="$v.dob.$touch(); removeError(); convertIntoDateFormat();" mode="date">
                  <template v-slot="{ inputValue, inputEvents }">
                    <input class="custom-input" placeholder="mm/dd/yyyy" size=10 maxlength=10
                           :class="{ 'invalid': $v.dob.$error || formErrors['dob_message'] }"
                           :value="inputValue.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d\/]/g, '')"
                           v-on="inputEvents" />
                  </template>
                </v-date-picker>
                <div class="form-group__message text-left ml-3 mt-2 mb-2" v-if="!$v.dob.required && $v.dob.$error">
                  Dob field is required
                </div>
                <div class="form-group__message dob-style ml-3 mt-2 mb-2" v-if="$v.dob.required && $v.dob.$error">
                  Age must be between 18 and 100 in order to proceed
                </div>
                <div class="error_message" v-if="formErrorFlag['dob'] == true">{{ formErrors['dob_message'] }}</div>
              </div>
            </div>
          </div>
          <div id="incomeError" class="aetna-alert-danger" v-if="stateError == 1">Plan not available in your state.
            Please change
            the state by editing your basic info above.</div>
          <div id="incomeError" class="aetna-alert-danger" v-if="tierError == 1">Plan not available for this tier.
            Please change
            the tier by editing your basic info above.</div>
          <div id="incomeError" class="aetna-alert-danger" v-if="ageError == 1">Plan not available for selected age
            group. Please
            edit your basic info above.</div>
          <div id="incomeError" class="aetna-alert-danger" v-if="metError == 1">Plan not available in the area. Please
            choose
            another area by editing your basic info above.</div>
          <div id="incomeError" class="aetna-alert-danger" v-if="planAgeError == 1">{{ planAgeErrorMsg }}</div>
          <div id="incomeError" class="aetna-alert-danger" v-if="portalError == 1">{{ portalErrorMsg }}</div>
          <div id="blackoutError" class="aetna-alert-danger" v-if="blackoutErr.value == true">{{ blackoutErr.message }}
          </div>
          <div class="form-group landignModal-btn text-center">
            <button class="univ-btn" @click="submitAPI">Submit</button>
            <button class="mt-3 univ-btn-transparent" @click="closeModal">Cancel</button>
          </div>
        </div>
        <div v-if="renderProceedConformation">
          <confirmation :effective="effective_date" :isFromAetnaEnroll="true" @routeBackToFilterEdit="routeBackToFilterEdit" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { checkValidZip, isOver18 } from "../../../utils/validators";
import axios from "axios";
import Confirmation from "../../../components/Confirmation";
import Api from "../../../includes/Api";

export default {
  name: "AetnaEnrollNowModal",
  components: { Confirmation },
  data() {
    return {
      tempId: '',
      dob: '',
      tier: '',
      zip: '',
      gender: '',
      eprocess: '',
      agent_id: '',
      group_id: '',
      enrollmentType: '',
      formErrorFlag: [],
      formErrors: [],
      genderOptions: [
        { value: '', text: 'Choose Gender', disabled: true},
        { value: '0', text: 'Male' },
        { value: '1', text: 'Female'}
      ],
      tierOptions: [
        { value: '', text: 'Choose Tier', disabled: true},
        { value: 'IO', text: 'Member Only'},
        { value: 'IS', text: 'Member + Spouse'},
        { value: 'IC', text: 'Member + Children'},
        { value: 'IF', text: 'Family'}
      ],
      dentalPlanPid: process.env.VUE_APP_DENTAL_PLAN_ID,
      plans: [],
      enrollmentIdError: 0,
      ageError: 0,
      metError: 0,
      tierError: 0,
      stateError: 0,
      planAgeError: 0,
      planAgeErrorMsg: '',
      portalError:0,
      portalErrorMsg: '',
      dependentError:'',
      blackoutErr: {
        value: false,
        message: '',
      },
      effectiveDate: [],
      effective_date: '',
      renderProceedConformation: false
    }
  },
  validations: {
    zip: {
      required,
      checkValidZip
    },
    tier: {
      required
    },
    dob: {
      required,
      isOver18
    }
  },
  mounted() {
    let app = this;
    app.fetchRepInformation();
  },
  methods: {
    submitAPI () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false
      } else {
        let app = this;
        app.restFilterPlanError();
        let params = {
          zip: app.zip,
          dob: app.dob,
          is_smoker: app.is_smoker,
          gender: 0,
          tier: app.tier,
          eprocess: app.eprocess,
          agent_id: app.agent_id,
          group_id: app.group_id,
          platform_group_id: app.platformId,
          annual_incode: null,
          user_id: app.userId,
          open_type:app.open_type,
        };
        window.localStorage.setItem("selectedTier", app.tier);
        if (app.tempId == null) {
          axios.post(process.env.VUE_APP_API_BASE + '/add-filter-for-new-user', {
            zip: app.zip,
            dob: app.dob,
            gender: 0,
            tier: app.tier,
            eprocess: app.eprocess,
            agent_id: app.agent_id,
            group_id: app.group_id,
            platform_group_id: app.platformId,
            annual_incode: null,
            type: app.enrollType,
            open_type:app.open_type,
          })
              .then(function (response) {
                if (response.data.status == 'success') {
                  window.localStorage.setItem('tempId', response.data.data.temp_enrollment_id);
                  app.tempId = response.data.data.temp_enrollment_id
                  app.formErrorFlag = [];
                  app.formErrors = [];
                  app.getPlanPricing();
                }
              })
              .catch(function (error) {
                app.formErrorFlag = [];
                app.formErrors = [];
                console.log(error);
                if (error.response.data.status == 'error') {
                  error.response.data.data.forEach(function (item) {
                    app.formErrorFlag[item.target_element] = true;
                    app.formErrors[item.target_element + '_message'] = item.error_message;
                  });
                }
              });
        } else {
          for (const key of Object.keys(params)) {
            if (params[key] === null || params[key] === '' || params[key] === undefined) {
              delete params[key];
            }
          }
          axios.put(process.env.VUE_APP_API_BASE + '/change-filter-info/' + app.tempId, params)
              .then(function (response) {
                if (response.data.status == 'success') {
                  app.getPlanPricing();
                }
              })
              .catch(function (error) {
                app.formErrorFlag = [];
                app.formErrors = [];
                console.log(error);
                if (error.response.data.status == 'error') {
                  error.response.data.data.forEach(function (item) {
                    app.formErrorFlag[item.target_element] = true;
                    app.formErrors[item.target_element + '_message'] = item.error_message;
                  });
                }
              });
        }
      }
    },
    getFilters () {
      let app = this;
      if(app.tempId){
        axios.get(process.env.VUE_APP_API_BASE + '/get-enrollment-temp-info', {
          params: {
            enrollment_id: app.tempId,
            column: 'filter_info'
          }
        })
            .then(function (response) {
              if (response.data.status == 'success') {
                app.dob = response.data.data.result.dob;
                app.zip = response.data.data.result.zip;
                app.state = response.data.data.result.state;
                window.localStorage.setItem('state',app.state)
                app.tier = response.data.data.result.tier;
                app.gender = response.data.data.result.gender;
                app.annualIncome = response.data.data.result.annual_incode;
                if (app.gender == '0') {
                  app.genderOpt = "M";
                } else {
                  app.genderOpt = "F";
                }
                if (app.enrollmentType == 'existing-user') {
                  app.userId = response.data.data.result.user_id;
                }
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      }
    },
    renderModal () {
      let app = this
      app.fetchRepInformation();
      app.getFilters();
      app.restFilterPlanError()
      app.$v.$reset();
    },
    removeError () {
      let app = this
      app.formErrors = []
      app.restFilterPlanError();
    },
    closeModal () {
      let app = this
      app.$bvModal.hide('aetna-modal')
    },
    fetchRepInformation () {
      let app = this;
      app.tempId = window.localStorage.getItem('tempId');
      app.agent_id = window.localStorage.getItem('AGENT_ID');
      app.group_id = window.localStorage.getItem('group_id');
      app.platformId = window.localStorage.getItem('portal_id');
      app.enrollType = window.localStorage.getItem('enroll_type');
      app.eprocess = window.localStorage.getItem('eprocess');
      app.open_type = window.localStorage.getItem('open_type');
      app.renderProceedConformation = false
    },
    convertIntoDateFormat () {
      let app = this
      let date = this.dob
      let dd = date.getDate()
      let mm = date.getMonth() + 1
      let yyyy = date.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      app.dob = mm + '/' + dd + '/' + yyyy;
    },
    restFilterPlanError () {
      let app = this
      app.tierError = 0;
      app.planAgeError = 0;
      app.stateError = 0;
      app.enrollmentIdError = 0,
      app.ageError = 0,
      app.metError = 0,
      app.tierError = 0,
      app.planAgeErrorMsg = '',
      app.portalError = 0,
      app.portalErrorMsg = '',
      app.dependentError = '',
      app.blackoutErr = {
        value: false,
        message: '',
      },
      app.plans = []
    },
    addCart: function (pid, ppid) {
      let app = this;
      axios.post(process.env.VUE_APP_API_BASE + '/add-plan-to-cart', {
        enrollment_id: app.tempId,
        plan_id: pid,
        plan_pricing_id: ppid,
      })
          .then(function (response) {
            console.log(response);
            if (response.data.status == 'success') {
              app.getEffDate();
              app.getNavTabs();
              app.renderProceedConformation = true
            }
          })
          .catch(function (error) {
            console.log(error.response);
            if (error.response.data[0].status == 'error') {
              if (error.response.data[0].errorCode == 'blackout') {
                app.blackoutErr.value = true;
                app.blackoutErr.message = error.response.data[0].message;
              } else if (error.response.data[0].errorCode == 'already-active') {
                console.log('correct');
                app.alreadyActive = true;
                app.alreadyActiveMessage = error.response.data[0].message;
              } else {
                app.blackoutErr.value = false;
                app.alreadyActive.value = false;
              }
            }
          });
    },
    getPlanPricing () {
      let app = this;
      let pid = app.dentalPlanPid;
      axios.get(process.env.VUE_APP_API_BASE + '/get-plan-pricing-detail', {
        params: {
          pid: pid,
          enrollment_id: app.tempId,
        }
      })
      .then(function (response) {
        if (response.data.status == 'success') {
          response.data.plans.forEach(function (item) {
            app.plans.push(item);
          });
          console.log('plans', app.plans[0])
          app.newQuestionsError = 0;
          app.addCart(pid, app.plans[0].ppid)
         } else {
          app.plans = [];
          if (response.data.status == 'error' && response.data.errorCode == 'enrollmentIdError') {
            app.enrollmentIdError = 1;
          } else {
            app.enrollmentIdError = 0;
          }
          if (response.data.status == 'error' && response.data.errorCode == 'salaryError') {
            app.salaryError = 1;
          } else {
            app.salaryError = 0;
          }
          if (response.data.status == 'error' && response.data.errorCode == 'ageError') {
            app.ageError = 1;
          } else {
            app.ageError = 0;
          }
          if (response.data.status == 'error' && response.data.errorCode == 'tierError') {
            app.tierError = 1;
          } else {
            app.tierError = 0;
          }
          if (response.data.status == 'error' && response.data.errorCode == 'stateError') {
            app.stateError = 1;
          } else {
            app.stateError = 0;
          }
          if (response.data.status == 'error' && response.data.errorCode == 'metError') {
            app.metError = 1;
          } else {
            app.metError = 0;
          }
          if (response.data.status == 'error' && response.data.errorCode == 'smokerError') {
            app.smokerError = 1;
          } else {
            app.smokerError = 0;
          }
          if (response.data.status == 'error' && response.data.errorCode == 'planAgeError') {
            app.planAgeError = 1;
            app.planAgeErrorMsg = response.data.message;
          } else {
            app.planAgeError = 0;
            app.planAgeErrorMsg = '';
          }
          if (response.data.status == 'error' && response.data.errorCode == 'newQuestionsError') {
            app.newQuestionsError = 1;
          } else {
            app.newQuestionsError = 0;
          }
          if (response.data.status == 'error' && response.data.errorCode == 'hourError') {
            app.hourError = 1;
          } else {
            app.hourError = 0;
          }
          if (response.data.status == 'error' && response.data.errorCode == 'portalError') {
            app.portalError = 1;
            app.portalErrorMsg = response.data.message;
          } else {
            app.portalError = 0;
            app.portalErrorMsg = '';
          }
        }
      })
      .catch(function (error) {
        console.log(error)
      });
    },
    getEffDate: function () {
      let app = this;
      console.log("final" + app.effective_date);
      axios
          .get(process.env.VUE_APP_API_BASE + "/get-cart-effective-dates", {
            params: {
              enrollment_id: app.tempId,
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.effectiveDate = [];
              response.data.data.result.forEach(function (item) {
                app.effectiveDate.push(item);
              });
            }
            if (app.effective_date == null || app.effective_date == "") {
              app.effective_date = app.effectiveDate[0];
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    cartCheckout: function () {
      let app = this;
      axios
          .post(process.env.VUE_APP_API_BASE + "/cart-checkout", {
            enrollment_id: app.tempId,
            effective_date: app.effective_date,
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.$bvModal.hide('cart_confirmation_page');
              window.localStorage.setItem('effectiveDate', app.effective_date)
            }
          })
          .catch(function (error) {
            if (error.response.data.status == "error") {
              error.response.data.data.forEach(function (item) {
                if (item.target_element == "effective_date") {
                  app.effective_error = true;
                  app.effective_error_msg = item.error_message;
                }
              });
            }
            console.log(error);
          });
    },
    getNavTabs: function() {
      let nav_array = [];
      Api.get('/get-dynamic-tabs?enrollment_id='+ this.tempId).then((response) => {
        console.log(response.data.data.result);
        let index = 1;
        let order = 0;
        for (order = 0; order <= 10; order++) {
          response.data.data.result.forEach((nav) => {
            if(nav.order == order){
              if(nav.tab_name == 'primary_info' && nav.required){
                nav_array.push({"index": index, "title": 'Personal Information', "name": 'personal-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'contact_info' && nav.required){
                nav_array.push( {"index": index, "title": 'Contact Information', "name": 'contact-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'employer_info' && nav.required){
                nav_array.push({"index": index, "title": 'Employer Information', "name": 'employer-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'beneficiary_info' && nav.required){
                nav_array.push({"index": index, "title": 'Beneficiary Information', "name": 'beneficiary-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'primary_health_info' && nav.required){
                nav_array.push({"index": index, "title": 'Health Questions', "name": 'health-questions', "visible": true});
                index++;
              }
              if(nav.tab_name == 'dependent_info' && nav.required){
                nav_array.push({"index": index, "title": 'Dependent Information', "name": 'dependent-information', "visible": true});
                index++;
              }
              if(nav.tab_name == 'review_info' && nav.required){
                nav_array.push({"index": index, "title": 'Review & Confirmation', "name": 'review-confirm', "visible": true});
                index++;
              }
              if(nav.tab_name == 'agreement_info' && nav.required){
                nav_array.push({"index": index, "title": 'Agreement & Signature', "name": 'agreement-signature', "visible": true});
                index++;
              }
              if(nav.tab_name == 'billing_info' && nav.required){
                nav_array.push({"index": index, "title": 'Billing Information', "name": 'billing-information', "visible": true});
                index++;
              }
            }
          });
        }
        window.localStorage.setItem("nav_array", JSON.stringify(nav_array));
      }).catch(() => {
      }).then(() => {
      });
    },
    routeBackToFilterEdit () {
      let app = this
      app.renderProceedConformation = false
    }
  }
}
</script>

<style scoped>
.invalid-field {
  position: relative;
  top: 0;
}

.aetna-alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 15px 8px;
  margin: 10px 0;
  border-radius: 5px;
}

</style>