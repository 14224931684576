var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_c('div',{staticClass:"landingContentwrap pb-2"},[_c('div',{staticClass:"container container-lg-fluid"},[_c('div',{staticClass:"row"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"landingContent py-3"},[_c('div',{staticClass:"text-center enrollNow"},[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.aetna-modal",modifiers:{"aetna-modal":true}}]},[_vm._v("Click here")]),_vm._m(6)])])])])])]),_c('div',{staticClass:"landingContentwrap pt-0"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(7),_vm._m(8),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"text-center enrollNow mt-2"},[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.aetna-modal",modifiers:{"aetna-modal":true}}]},[_vm._v("Click Here")]),_vm._m(9)])])])])]),_c('aetna-enroll-now-modal')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landingHeader py-1"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-sm-5"},[_c('img',{staticClass:"w-100 img-fluid",attrs:{"src":require("./images/aetnadental.png"),"alt":""}})]),_c('div',{staticClass:"col-sm-3"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("./images/teledental.png"),"alt":""}})]),_c('div',{staticClass:"col-sm-1"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("./images/outlook.jpeg"),"alt":""}})]),_c('div',{staticClass:"col-sm-3"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("./images/mg.png"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landingBanner"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("./images/banner.png"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mobile-show"},[_c('div',{staticClass:"content-bannerLanding"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("./images/content-banner.png"),"alt":""}}),_c('h5',[_vm._v("Talk with a Live Dentist on Teledental.com Anywhere, 24/7")]),_c('p',{staticClass:"mb-0"},[_vm._v("Want to consult with a dentist online?")]),_c('p',{staticClass:"mb-0"},[_vm._v("Use "),_c('u',[_vm._v("Teledental video consultation")]),_vm._v(" with a "),_c('u',[_vm._v("Dentist")])]),_c('p',{staticClass:"mb-0"},[_vm._v("Consult with a "),_c('u',[_vm._v("live Dentist")]),_vm._v(" 24/7 - any time, from any place ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-7 col-lg-8"},[_c('div',{staticClass:"landingContent aetnaBlock"},[_c('img',{staticClass:"img-fluid mb-4 mt-4",attrs:{"src":require("./images/aetnadental.png"),"alt":""}}),_c('h2',[_vm._v("Savings Program Highlights:")]),_c('ul',{staticClass:"mb-4"},[_c('li',[_vm._v("No Annual Limits allow year long savings.")]),_c('li',[_vm._v("No forms to file or unnecessary paperwork.")]),_c('li',[_vm._v("No required referrals to access specialists.")]),_c('li',[_vm._v("No Age or Health Restrictions - Everyone Accepted.")]),_c('li',[_vm._v("No SS# required.")])]),_c('h2',[_vm._v("Program Overview")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-5 col-lg-4 mobile-hide"},[_c('div',{staticClass:"content-bannerLanding"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("./images/content-banner.png"),"alt":""}}),_c('h5',[_vm._v("Talk with a Live Dentist on Teledental.com Anywhere, 24/7")]),_c('p',{staticClass:"mb-0"},[_vm._v("Want to consult with a dentist online?")]),_c('p',{staticClass:"mb-0"},[_vm._v("Use "),_c('u',[_vm._v("Teledental video consultation")]),_vm._v(" with a "),_c('u',[_vm._v("Dentist")])]),_c('p',{staticClass:"mb-0"},[_vm._v("Consult with a "),_c('u',[_vm._v("live Dentist")]),_vm._v(" 24/7 - any time, from any place ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"landingContent aetnaBlock"},[_c('ul',[_c('li',[_vm._v("In most instances, members save up to 50% per visit on certain dental services including: braces, cleanings, x-rays, root canals, corwns, dentures, implants and more! Save at general dentists and specialists such as Endodontist, Orthodontist, Periodontists, Oral Surgeons and other specialists, more than 262,000** dental practice locations available nationwide For provides please visit "),_c('a',{attrs:{"href":"https://dentallookup.com","target":"_blank"}},[_vm._v("www.dentallookup.com")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contactInfo mt-3"},[_c('a',{attrs:{"href":"tel:"}},[_c('i',{staticClass:"fas fa-phone-alt"}),_vm._v(" XXX-XXX-XXXX ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 mb-4"},[_c('div',{staticClass:"landingContent"},[_c('img',{staticStyle:{"max-width":"185px"},attrs:{"src":require("./images/outlook.jpeg"),"alt":""}}),_c('ul',[_c('li',[_vm._v("Nationwide vision care discount network consisting of over 11,500 providers.")]),_c('li',[_vm._v("Average discounts from 10% to 50% on frames, prescription lenses and sunglasses.")]),_c('li',[_vm._v("Mail-order contact lenses replacement program.")]),_c('li',[_vm._v("Discounts on corrective surgery.")]),_c('li',[_vm._v("No paperwork.")]),_c('li',[_vm._v("Customer services representatives (rather than recodings) answer questions and direct")]),_c('li',[_vm._v("Members to Outlook Vision provides.")]),_c('li',[_vm._v("100% guaranteed satisfaction for 30 days.")]),_c('li',[_vm._v("Membership includes the entire family.")])]),_c('p',[_vm._v("Provider finder: "),_c('a',{attrs:{"href":"https://outlookvision.com","target":"_blank"}},[_vm._v("www.outlookvision.com")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 mb-4"},[_c('div',{staticClass:"landingContent"},[_c('img',{staticClass:"w-100 img-fluid mb-3",attrs:{"src":require("./images/mg.png"),"alt":""}}),_c('p',[_vm._v("Select Rx Discount is a membership designed to help your save money. "),_c('br'),_vm._v(" Free and Discounted Prescription Medication")]),_c('ul',[_c('li',[_vm._v("Over 500 prescription medications")]),_c('li',[_vm._v("Membership starts at $10 a month")]),_c('li',[_vm._v("Membership includes free Acute Medications, Maintenance Medications, Retail Rx Sacings Card, Brand - Name and Generic Mail - Order Savings, Diabetic Supplies Savings, Vitamin Savings")])]),_c('a',{attrs:{"href":"https://www.medaffordglobal.com/index.aspx","target":"_blank"}},[_vm._v("https://www.medaffordglobal.com/index.aspx")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contactInfo mt-3"},[_c('a',{attrs:{"href":"tel:"}},[_c('i',{staticClass:"fas fa-phone-alt"}),_vm._v(" XXX-XXX-XXXX ")])])
}]

export { render, staticRenderFns }